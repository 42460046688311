import React from "react";
import logo from "./logo.png";
import { PixelButton } from "./PixelButton/PixelButton";
import { Signer } from "ethers";

export function AppHeaderBar({
  signer, setShouldLogin, accounts, title
}: {
  signer: Signer;
  setShouldLogin: (shouldLogin: boolean) => void;
  accounts: string[];
  title?: string;
}) {
  if (!title)
    title = "fondue.land";
  const ADDR = accounts[0]
    ? accounts[0].slice(0, 5) + "..." + accounts[0].slice(-3)
    : "";
  return (
    <div className="App-header-bar">
      <img
        style={{ marginLeft: "0.25em", marginRight: "0.75em" }}
        src={logo}
        className="App-logo"
        alt="logo" />
      <div id="title">{title}</div>
      <a href="https://fondue.land/migrate" target="_blank" style={{ marginLeft: 'auto', fontSize: '0.8ch', marginRight: '1ch' }}>MIGRATE</a>
      <a href="https://docs.fondue.land/" target="_blank" style={{ marginLeft: '1ch', fontSize: '0.8ch', marginRight: '1ch' }}>DOCS</a>
      {!signer || accounts.length == 0 ? (
        <PixelButton
          style={{
            marginTop: "-0.5em",
            minWidth: "8ch",
            height: "2ch",
            marginRight: "1ch",
          }}
          onClick={() => setShouldLogin(true)}
        >
          CONNECT
        </PixelButton>
      ) : (
        <>
          <div
            className="App-header-bar-account"
          >
            {ADDR}
          </div>
            {/* <PixelButton
                style={{
                marginLeft: "1em",
                marginTop: "-0.5em",
                minWidth: "11ch",
                height: "2ch",
                marginRight: "1ch",
                }}
                onClick={() => setShouldLogin(false)}
            >
                DISCONNECT
            </PixelButton> */}
        </>
      )}
    </div>
  );
}
