import React, { useEffect, useMemo, useState } from "react";
import "./App.css";
import detectEthereumProvider from "@metamask/detect-provider";
import useProvider from "./helpers/useProvider";
import { PixelBox } from "./PixelBox/PixelBox";
import { PixelButton } from "./PixelButton/PixelButton";
import { useContract } from "./helpers/useContract";
import { Contract, ethers, Signer } from "ethers";
import { useFondueTicketsV2 } from "./helpers/useFondueTicketsV2";
import { useFondueTickets } from "./helpers/useFondueTickets";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import moment from "moment";
import { Migration } from "./_pages/Migration";
import { AppHeaderBar } from "./AppHeaderBar";
import { useFondueTicketsV3 } from "./helpers/useFondueTicketsV3";
import { Jackpot } from "./_components/Jackpot";
const MAX_KEYS_PER_TX = 1000;

function App() {
  const [shouldLogin, setShouldLogin] = React.useState(true);

  const { accounts, signer, provider } = useProvider(shouldLogin);
  const [mice, setMice] = React.useState(0);
  useMemo(() => console.clear(), []);


  return (
    <div className="App">
      <Routes>
        <Route
          path="/migrate"
          element={
            <Migration
              accounts={accounts}
              signerOrProvider={signer ? signer : (provider as any)}
            />
          }
        />
        {/* <Route
          path="/"
          element={
            <Migration
              accounts={accounts}
              signerOrProvider={signer ? signer : (provider as any)}
            />
          }
        /> */}
        <Route
          path="/"
          element={
            <Dashboard
              accounts={accounts}
              mice={mice}
              UpdateMice={UpdateMice}
              setShouldLogin={setShouldLogin}
              signer={signer ? signer : (provider as any)}
            />
          }
        />
      </Routes>

      <NotificationContainer />
    </div>
  );

  function UpdateMice(mice: number) {
    if (mice > MAX_KEYS_PER_TX) {
      setMice(MAX_KEYS_PER_TX);
    } else setMice(Number(mice));
  }
}

export default App;

function Presale({
  accounts,
  mice,
  UpdateMice,
  signer,
  setShouldLogin,
}: {
  accounts: string[];
  mice: number;
  UpdateMice: (mice: number) => void;
  signer: Signer;
  setShouldLogin: (shouldLogin: boolean) => void;
}) {
  const {
    price,
    minted,
    approvedFor,
    keyBalance,
    miceBalance,
    endDate,
    approveAllMice,
    purchaseWithMice,
  } = useFondueTickets(signer);

  useEffect(() => {
    if (!signer) UpdateMice(0);
  }, [signer]);

  return (
    <header className="App-header">
      <AppHeaderBar
        signer={signer}
        setShouldLogin={setShouldLogin}
        accounts={accounts}
        title="KEY PRESALE"
      />
      <div className="App-presale">
        <div className="App-fondue-info">
          <p>INFO BOARD</p>
          <PixelBox className="App-presale-info">
            <span style={{ fontSize: "0.75em" }}>
              welcome to the fondue.land presale
            </span>
            <br />
            <br />
            <span style={{ fontSize: "0.75em" }}>
              trade your cheesedao mice for{" "}
              <b style={{ fontSize: "1em", color: "white" }}>keys</b>. keys can
              later be entered for a chance to win a jackpot of mice
            </span>
            <br />
            <br />
            <span style={{ fontSize: "0.75em" }}>
              <b style={{ fontSize: "1em", color: "white" }}>CHEEZ</b> earned
              from mice will be used to buy mice off market to increase the{" "}
              <b style={{ fontSize: "1em", color: "white" }}>Fondue Pots</b>{" "}
              value
            </span>
            <br />
            <br />
            <span style={{ fontSize: "0.75em" }}>
              Keys can be minted post-presale, the profits of which will be
              redistributed pro-rata to all cheezers inside the{" "}
              <b style={{ fontSize: "1em", color: "white" }}>Fondue Pot</b>
            </span>
            <br />
            <br />
            <span style={{ fontSize: "0.75em" }}>
              For more info ask for{" "}
              <b style={{ fontSize: "1em", color: "white" }}>creative</b> on{" "}
              <a
                target="_blank"
                href="https://discord.gg/dU4usXbqP6"
                rel="noreferrer"
                style={{
                  color: "#FFE251",
                  fontWeight: "bold",
                  fontStyle: "unset",
                  fontSize: "1.1em",
                }}
              >
                discord
              </a>
            </span>
          </PixelBox>
        </div>
        <div className="App-presale-mint">
          <h3>KEY PRESALE</h3>
          <p>{endDate}</p>
          <PixelBox className="App-presale-dialog">
            <div className="App-presale-total-minted">
              <span>{minted} / 25000</span>
              <div
                style={{ width: `${(minted / 25000) * 100}%` }}
                className="progress"
              />
            </div>
            <h4 style={{ marginBottom: "1em", marginTop: "2ch" }}>MINT KEYS</h4>
            <span style={{ width: "calc(100% - 2.25ch)" }} className="input-wrapper">
              <div className="max-mice">
                {!!signer ? (
                  <PixelButton onClick={() => UpdateMice(miceBalance)}>
                    max
                  </PixelButton>
                ) : null}
              </div>
              {!!signer ? (
                <span className="mice-balance">{miceBalance}</span>
              ) : null}
              <span className={"input-label" + (signer ? " mice" : "")}>
                🐭
              </span>
              <input
                value={mice}
                placeholder="0 "
                className="mice-input"
                onChange={(e) => handleMiceInput(e)}
              />
              {mice === MAX_KEYS_PER_TX ? (
                <span className="input-message">(max tx limit)</span>
              ) : null}
            </span>
            <div className="arrow-box">
              <span>👇</span>
            </div>
            <span
              style={{ width: "calc(100% - 2.25ch)" }}
              className="input-wrapper"
            >
              {!!signer ? (
                <span className="mice-balance">{keyBalance}</span>
              ) : null}
              <span className={"input-label" + (signer ? " mice" : "")}>
                🔑
              </span>
              <input
                type="number"
                placeholder="0"
                className="mice-input"
                value={mice * 50}
                disabled={true}
              />
            </span>
            <br />
            <b style={{ fontSize: "0.5em" }}> 1 mouse = 50 keys</b>
            <br />
            {!signer ? (
              <PixelButton onClick={() => setShouldLogin(true)}>
                Connect Wallet
              </PixelButton>
            ) : approvedFor ? (
              <PixelButton
                disabled={mice === 0}
                onClick={() => {
                  // web3 signin
                  purchaseWithMice(mice).catch((err: any) =>
                    NotificationManager.error(err.data.message, null, 5000)
                  );
                }}
              >
                MINT{" "}
                <span
                  style={{
                    fontSize: "2em",
                    marginTop: "-0.4em",
                    marginLeft: "0.5em",
                  }}
                >
                  🗝
                </span>
                <span style={{ fontSize: "0.75em" }}>'s</span>
              </PixelButton>
            ) : (
              <PixelButton
                onClick={() => {
                  // web3 signin
                  approveAllMice()
                    .then()
                    .catch((err: any) =>
                      NotificationManager.error(err.data.message, null, 5000)
                    );
                }}
              >
                APPROVE
              </PixelButton>
            )}
          </PixelBox>
        </div>
      </div>
    </header>
  );

  function handleMiceInput(e: React.ChangeEvent<HTMLInputElement>): void {
    const input = Math.floor(Number(e.target.value)) || 0;
    // trim leading zeros
    const mice = Number(input.toString().replace(/^0+/, ""));
    if (isNaN(Math.floor(Number(e.target.value)))) return;
    UpdateMice(mice);
  }
}

function Dashboard({
  accounts,
  mice,
  UpdateMice,
  setShouldLogin,
  signer,
}: {
  accounts: string[];
  mice: number;
  UpdateMice: (mice: number) => void;
  setShouldLogin: (bool: boolean) => void;
  signer: Signer;
}) {
  const {

    daiContract,
    daiBalance,
  } = useFondueTicketsV2(signer);
  const {
    approvedFor,
    beforeRound,
    blocks,
    contract,
    currentRound,
    earnedDai,
    endDate,
    endDateFormated,
    events,
    fondueBalance,
    fondueContract,
    fondueTotalSupply,
    isPresale,
    raisedMice,
    miceGoal,
    maxMiceGoal,
    presaleEnddate,
    jackpotContract,
    keyBalance,
    keysApprovedForFondue,
    lastClaimed,
    maxDepositable,
    places,
    price,
    totalSupply,
    UpdateAll,
    miceBalance,
    miceContract,
    miceApproved
  } = useFondueTicketsV3(signer);
  const [showModal, setShowModal] = useState(false);

  const IS_FIRST_PLACE = useMemo(() => places[0]?.toLowerCase() === accounts[0]?.toLowerCase(), [places, accounts]);

  return (
    <header className="App-header">
      <AppHeaderBar
        signer={signer}
        setShouldLogin={setShouldLogin}
        accounts={accounts}
        title="FONDUE.LAND"
      />
      <div className="App-dashboard">
        <PixelBox className="App-dashboard-earnings">
          <h3>STATS</h3>
          {KeysInput(keyBalance, totalSupply, () => setShowModal(true))}
          {EntryEarnings({ fondueBalance: Number((Number(fondueBalance.toString()) / 10 ** 9).toFixed(3)), fondueContract, earnedDai, lastClaimed, signer, Update: UpdateAll })}
          {/* <p style={{ marginTop: "auto", display: "flex-block" }}>
            Round time totals 45 days, 20 hours, and 30 minutes.
          </p> */}
        </PixelBox>
        {BuyKeys({ mice, daiBalance, approvedFor, daiContract: daiContract, price, contract: contract, Update: UpdateAll, beforeRound })}
        {Jackpot({ endDate, blocks, currentRound, IS_FIRST_PLACE,raisedMice, places,miceGoal,presaleEnddate, endDateFormated, isPresale, beforeRound })}
        {
          !beforeRound ? <PixelBox className="App-dashboard-enter-jackpot">
          <h3>ENTER THE GAME</h3>
          <p>
            If you're the last individual to <span style={{ fontSize: 'inherit', color: "#ffe251", textShadow: "2px 2px 0px #0c0b0b" }}>"Take The Pot"</span> you will win all the
            mice in the jackpot!
          </p>
          <TakeThePotInput signer={signer} keyBalance={keyBalance} maxDepositable={maxDepositable} jackpotContract={jackpotContract} ticketContract={contract} keysApprovedForFondue={keysApprovedForFondue} Update={UpdateAll} />
          <div className="current-pot-size">
            <span> {endDate == 0 ? 0 : 500 - maxDepositable} / 500 </span>
            <div className="background" style={{ width: `${((endDate == 0 ? 0 : 500 - maxDepositable) / 500) * 100}%` }}></div>
          </div>
        </PixelBox> : raisedMice < maxMiceGoal ? Date.now() / 1000 < presaleEnddate ? <PixelBox className="App-dashboard-enter-jackpot">
          <h3>TRADE MICE?</h3>
          <p>Exchange your mice for keys at a <span style={{ fontSize: 'inherit', color: "#ffe251", textShadow: "2px 2px 0px #0c0b0b" }}>1 : 20</span> rate!</p>
          <EnterPresale miceBalance={miceBalance} miceApproved={miceApproved} miceContract={miceContract} jackpotContract={jackpotContract} Update={UpdateAll} />
          
        </PixelBox> : raisedMice < miceGoal ? <PixelBox className="App-dashboard-enter-jackpot">
          <h3>ROUND FUNDRAISER</h3>
          <p>
            Not enough mice have been raised yet. Initiate a 48h fundraiser to raise the required amount of mice.
          </p>
          <PixelButton disabled={!jackpotContract} onClick={() => jackpotContract?.StartNextPresale()} style={{
              marginTop: 'auto'
            }}>
            START
          </PixelButton>
          </PixelBox> : <PixelBox className="App-dashboard-enter-jackpot">
            <h3>ROUND START</h3>
            <p>
              The round fundraiser has ended with enough mice, feel free to start the next round.
            </p>
            <PixelButton disabled={!jackpotContract} onClick={() => jackpotContract?.StartNextRound()} style={{
              marginTop: 'auto'
            }}>
              START
            </PixelButton>
          </PixelBox>
          : <PixelBox className="App-dashboard-enter-jackpot">
          <h3>ROUND START</h3>
          <p>
            The round fundraiser has ended with enough mice, feel free to start the next round.
          </p>
          <PixelButton disabled={!jackpotContract} onClick={() => jackpotContract?.StartNextRound()} style={{
            marginTop: 'auto'
          }}>
            START
          </PixelButton>
        </PixelBox>

        }
        <PixelBox className="App-dashboard-activity">
          <h3>ACTIVITY</h3>
          <ul>
            {events.map((i: {
              address: string;
              amount: number;
              amountOfKeys?: number;
              amountOfFondue?: number;
              cost?: number;
              id: string;
              createdAt: Date;
              presale?: boolean;
              type: "takeThePot" | "purchase" | "migration"
            }) => (
              <><li className={i.type} key={i.id}>
                <div className={`buyer ${i.type}`}>
                  <span className="highlight">{i.address?.slice(0, 5)}<span>...</span>{i.address?.slice(-3)}</span>{" "}
                  {i.type === "takeThePot" ?
                    <>took the pot using <span className="highlight">{numToShorthand(i.amount)} key{Math.floor(i.amount) !== 1 ? "s" : ""}!</span></>
                    : i.type === "migration" ?
                      <>migrated <span className="highlight">{numToShorthand(i?.amountOfKeys || 0)} keys</span> & <span className="highlight">{i?.amountOfFondue ? numToShorthand(Number((i?.amountOfFondue / 10 ** 9).toFixed(3))) : 0} fondue</span></>
                      : `bought ${numToShorthand(i.amount)} key${Math.floor(i.amount) !== 1 ? "s" : ""}` + (i?.presale ? ` with ${Math.floor(i.amount / 20)} ${i.amount == 20 ? "mouse" : "mice"}!` : "!")}
                </div>
                {/* display date as "x min ago" from i.createdAt */}
                <div className="timeago">{
                  moment(i.createdAt).fromNow()
                }</div>
              </li></>
            ))}
          </ul>
        </PixelBox>

        {showModal ? <SendKeysPopup closeModal={() => setShowModal(false)} contract={jackpotContract} from={accounts[0]}/> : null}
      </div>
    </header>
  );
}



function SendKeysPopup({ closeModal, contract, from = "" }: { closeModal: () => void; contract: Contract | null; from?: string; }) {
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState(0);
  
  return from.length == 0 ? null : <div className="popup-modal">
    <PixelBox className="popup-modal-content">
      <div className="popup-modal-header">
        <h3>SEND KEYS</h3>
        <span className="close" onClick={() => closeModal()}>&times;</span>
      </div>
      <div className="popup-modal-body">
        <p>
          You can send keys to another address by entering the address and the amount of keys you want to send.
        </p>
        <div className="popup-modal-inputs">
          <div className="popup-modal-input">
            <label>Address</label>
            <input type="text" className={"mice-input"} value={address} onChange={(e) => setAddress(e.target.value)} />
          </div>
          <div className="popup-modal-input">
            <label>Amount</label>
            <input type="number" className={"mice-input"} value={amount} onChange={(e) => setAmount(Number(e.target.value))} />
          </div>
        </div>
        <div className="popup-modal-button">
          <PixelButton className={"send-keys"} disabled={!address || !amount || !contract || !(TestAddress(address))} onClick={() => {
            contract?.safeTransferFrom(from, address, 0, amount, "0x");
          }}>
            Send
          </PixelButton>
        </div>
      </div>
      </PixelBox>
  </div>;
}

function TestAddress(addy: string): boolean {
  try {ethers.utils.getAddress(addy)} catch(err) {return false} return true;
}

function EntryEarnings({ fondueBalance, fondueContract, earnedDai, lastClaimed, signer, Update }: { fondueBalance: number; fondueContract: Contract | null; earnedDai: number; lastClaimed: number; signer: Signer; Update: () => void; }) {
  return <><span
    style={{ width: "100%", marginTop: "1.5ch" }}
    className="input-wrapper clickable"
    onClick={
      () => window.open("https://dexscreener.com/harmony/0x2410ac5f179bed7cdec3966a43c405d3ff1e7cd6")
    }
  >
    <span className={"input-title"}>
      FONDUE <HoverInfo text={"earn 1 fondue by depositing 1 key"} />
    </span>
    <input
      type="number"
      placeholder="0"
      className="mice-input "
      value={fondueBalance}
      disabled />
    <span style={{ position: 'absolute', right: '1ch', top: '1.5ch', zIndex: 1, fontSize: "0.75ch", color: "#0C0b0b" }}>FONDUE</span>
  </span>
    <span
      style={{ width: "100%", marginTop: "1ch" }}
      className="input-wrapper"
    >
      <span className={"input-title"}>
        EARNINGS
        <HoverInfo text={"will visually updates on next transaction"} />
      </span>
      <input
        type="number"
        placeholder="0"
        className="mice-input"
        value={Number(earnedDai.toPrecision(4))}
        disabled />
      <span style={{ position: 'absolute', right: '1ch', top: '1.5ch', zIndex: 1, fontSize: "0.75ch", color: "#0C0b0b" }}>DAI</span>
    </span>
    <PixelButton disabled={Number(fondueBalance) == 0} onClick={async () => { fondueContract?.claim(await signer.getAddress()).then((x: any) => x?.wait ? x.wait(1) : new Promise((res) => setTimeout(res, 5000)).then(Update() as any)) }}>
      CLAIM
    </PixelButton>
  </>;
}

function BuyKeys({ mice, beforeRound, daiContract, daiBalance, price, approvedFor, contract, Update }: { Update: () => void, beforeRound: boolean, mice: number; daiContract: Contract | null, price: number; daiBalance: number; approvedFor: number; contract: Contract | null; }) {

  const [keys, setKeys] = useState(0);

  const TotalCost = GetAvgCost(price, keys);

  // return a pixelbox that displays a temporarily disabled message instead
  return <PixelBox className="App-dashboard-buy-keys absolute">
    <h3>BUY KEYS</h3>
    <p style={{ lineHeight: '1.5ch' }}>Price of keys increases by <span style={{ fontSize: 'inherit', color: "#ffe251", textShadow: "2px 2px 0px #0c0b0b" }}>$0.0005/key</span> minted.<br />The next key will cost <span style={{ fontSize: 'inherit', color: "#ffe251", textShadow: "2px 2px 0px #0c0b0b" }}>${price.toFixed(2)}</span></p>
    <div className="balances">
      <span>BAL</span>
      <span>${daiBalance.toFixed(2)}</span>
    </div>
    <span
      style={{ width: "calc(100% - 2.25ch)" }}
      className="input-wrapper"
    >
      <input
        value={keys}
        placeholder="0 "
        className="mice-input"
        style={{ marginTop: "0.5ch" }}
        onChange={UpdateKeys}
        disabled={beforeRound} />
    </span>
    <div className="prices" style={{ position: 'relative' }}>
      {price === 0 ? <span>LOADING...</span> : <><span style={{ color: "#ffe251", textShadow: "2px 2px 0px #0c0b0b" }}>TOTAL</span>
        <span style={{ color: "#ffe251", textShadow: "2px 2px 0px #0c0b0b" }}>${TotalCost}</span></>}
    </div>
    <div style={{ width: "100%", height: '4ch', display: "flex", alignItems: 'center' }}>
      {approvedFor > Number(TotalCost)
        ? (<PixelButton disabled={keys === 0 || price === 0 || beforeRound} onClick={() => contract?.PurchaseKeys(keys).then(Update())}>
          BUY
        </PixelButton>)
        : (<PixelButton onClick={() => daiContract?.approve(contract?.address, ethers.constants.MaxUint256).then(Update())}>
          APPROVE
        </PixelButton>)}
    </div>
  </PixelBox>;

  function UpdateKeys(e: React.ChangeEvent<HTMLInputElement>) {
    let input = Math.floor(Number(e.target.value)) || 0;
    if (input >= MAX_KEYS_PER_TX) input = MAX_KEYS_PER_TX;
    setKeys(input);
  }
}

function numToShorthand(num: number) {
  let num_str = Number(num.toString().replace(/[^0-9.]/g, ''));
  if (num_str < 1000) {
    return num_str;
  }
  let si = [
    { v: 1E3, s: "K" },
    { v: 1E6, s: "M" },
    { v: 1E9, s: "B" },
    { v: 1E12, s: "T" },
    { v: 1E15, s: "P" },
    { v: 1E18, s: "E" }
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (num_str >= si[index].v) {
      break;
    }
  }
  return (num_str / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
}

function GetIncreaseInCost(index: number) {
  return (index * 0.00033);
}

function GetAvgCost(price: number, total: number) {
  const base = price;
  const last = price + GetIncreaseInCost(total - 1);
  return (((base + last) / 2) * total).toFixed(2);
}

function EnterPresale({miceBalance, miceApproved, miceContract, jackpotContract, Update}: { miceBalance: number, miceApproved: boolean, jackpotContract: Contract | null, miceContract: Contract | null, Update: () => void }) {
  const [value, setValue]  = useState(0);

  return <>
    <span
      style={{ width: "calc(100% - 2.5ch)" }}
      className="input-wrapper"
    >
      <div className="max-mice">
      {miceBalance ? (
        <PixelButton
          style={{ marginRight: `2.5ch` }}
          onClick={() => setValue(miceBalance)}
        >
          max
        </PixelButton>
      ) : null}
      </div>
      {miceBalance ? (
        <span className="mice-balance"
          style={{ marginRight: miceBalance.toString().length <= 1 ? '0.75ch' : undefined }}>{numToShorthand(miceBalance)}</span>
      ) : null}
      <span className={"input-label" + (miceBalance ? " mice" : "")}>🐭</span>
      <input
        placeholder="0"
        className="mice-input"
        value={value}
        onChange={UpdateValue}
      />
    </span>
    {miceApproved ? (<PixelButton disabled={value == 0} onClick={() => jackpotContract?.PurchaseKeysWithPresale(value).then(Update())}>
      TRADE MICE
    </PixelButton>) : <PixelButton onClick={() => miceContract?.setApprovalForAll(jackpotContract?.address, true).then(Update())}>
      APPROVE
    </PixelButton>}
  </>

  function UpdateValue(e: React.ChangeEvent<HTMLInputElement>) {
    let InputAmount = Math.floor(Number(e.target.value));
    if (isNaN(InputAmount)) return;
    if (InputAmount > miceBalance) return setValue(miceBalance);
    setValue(InputAmount);
  }
}

function TakeThePotInput({ signer, keyBalance, maxDepositable, jackpotContract, ticketContract, keysApprovedForFondue, Update }: { Update: () => void; signer: Signer; keyBalance: number; maxDepositable: number; jackpotContract: Contract | null; ticketContract: Contract | null; keysApprovedForFondue: boolean; }) {

  const [takeThePotInput, SetTakeThePotInput] = useState(0);

  return <><span
    style={{ width: "calc(100% - 2.5ch)" }}
    className="input-wrapper"
  >
    <div className="max-mice">
      {!!signer ? (
        <PixelButton
          style={{ marginRight: `2.5ch` }}
          onClick={() => SetTakeThePotInput(keyBalance > maxDepositable ? maxDepositable : keyBalance)}
        >
          max
        </PixelButton>
      ) : null}
    </div>
    {!!signer ? (
      <span className="mice-balance"
        style={{ marginRight: keyBalance.toString().length == 0 ? '0.75ch' : undefined }}>{numToShorthand(keyBalance)}</span>
    ) : null}
    <span className={"input-label" + (signer ? " mice" : "")}>🔑</span>
    <input
      placeholder="0"
      className="mice-input"
      value={takeThePotInput}
      onChange={UpdateTakeThePotInput}
    />
  </span>
    {keysApprovedForFondue ? (<PixelButton disabled={takeThePotInput == 0} onClick={() => jackpotContract?.EnterKeys(takeThePotInput).then(Update())}>
      TAKE THE POT
    </PixelButton>) : <PixelButton onClick={() => jackpotContract?.setApprovalForAll(jackpotContract?.address, true).then(Update())}>
      APPROVE
    </PixelButton>}

  </>;

  function UpdateTakeThePotInput(e: React.ChangeEvent<HTMLInputElement>) {
    let InputAmount = Math.floor(Number(e.target.value));
    if (isNaN(InputAmount)) return;
    if (InputAmount > maxDepositable) InputAmount = maxDepositable;
    if (InputAmount > keyBalance) return SetTakeThePotInput(keyBalance);
    SetTakeThePotInput(InputAmount);
  }
}

function KeysInput(keyBalance: number, totalSupply: number, openSendWindow: () => void) {
  return <span
    style={{ width: "100%", marginTop: "2ch" }}
    className="input-wrapper"
  >
    <span className={"input-title"}>YOUR KEYS</span>
    <span className={"input-title open-send"} style={{ left: 'unset', right: 0,  borderBottom: "2px dashed #00000033" }} onClick={openSendWindow}>SEND</span>
    <input
      placeholder="0"
      className="mice-input"
      value={numToShorthand(keyBalance)}
      disabled={true}
      style={{ fontSize: '0.8ch', height: '4em', fontWeight: 'bolder' }} />
    <span className={"input-overlay-middle"} >/</span>
    <span className={"input-overlay"} >{numToShorthand(totalSupply)}</span>
  </span>;
}

function HoverInfo({ text }: { text: string }) {
  return <div className="hover-info">
    <span className={"i-hover"}>i</span>
    <span className={"info-text"}>{text}</span>
  </div>;
}

