import React from "react";
import { PixelBox } from "../PixelBox/PixelBox";
import { ethers } from "ethers";

export function Jackpot({ endDate, blocks, currentRound, IS_FIRST_PLACE, places, endDateFormated, raisedMice, miceGoal, presaleEnddate, beforeRound, isPresale }: { endDate: number; currentRound: number; presaleEnddate: number; raisedMice: number; miceGoal: number; beforeRound: boolean; isPresale: boolean; blocks: number; IS_FIRST_PLACE: boolean; places: string[]; endDateFormated: string; }) {
  const GAME_FINISHED = endDate - blocks < 0 && endDate > 0;
  let SECONDS_LEFT_FOR_PRESALE = presaleEnddate - (Date.now() / 1000);
  if (SECONDS_LEFT_FOR_PRESALE < 0)
    SECONDS_LEFT_FOR_PRESALE = 0;
  // return <PixelBox className="App-dashboard-jackpot">
  //   <h4>ROUND CLOSED</h4>
  //   <h5>A new round will begin with <span>130 mice</span> in <span>April</span>, hope to see you then <span>♥</span> - <span>CB</span></h5>
  // </PixelBox>
  if (beforeRound && !isPresale)
    return StartingSoon(currentRound);
  if (!beforeRound && GAME_FINISHED)
    return GameFinished(currentRound, IS_FIRST_PLACE, places, raisedMice);
  if (beforeRound && isPresale)
    return Fundraiser(currentRound, raisedMice, SECONDS_LEFT_FOR_PRESALE);
  return JackpotRound(currentRound, places, IS_FIRST_PLACE, raisedMice, endDate, blocks, endDateFormated);
}
function JackpotRound(currentRound: number, places: string[], IS_FIRST_PLACE: boolean, raisedMice: number, endDate: number, blocks: number, endDateFormated: string) {
  return <PixelBox className="App-dashboard-jackpot">
    <h3>ROUND {currentRound}</h3>
    {places[0] === ethers.constants.AddressZero
      ? <h5>Be the <span>FIRST</span> to take</h5>
      : IS_FIRST_PLACE
        ? (<h5><span>YOU</span> are unlocking</h5>)
        : <h5><span>{places[0].slice(0, 5)}<span>...</span>{places[0].slice(-3)}</span> is unlocking</h5>}
    <h4>{raisedMice} MICE!</h4>
    {places[0] === ethers.constants.AddressZero
      ? <h5>and start the <span>FONDUE</span> pot!</h5>
      : <h5> {endDate > 0 ? `in ${endDate - blocks} blocks (${endDateFormated})` : ''}</h5>}
  </PixelBox>;
}
function Fundraiser(currentRound: number, raisedMice: number, SECONDS_LEFT_FOR_PRESALE: number) {
  return <PixelBox className="App-dashboard-jackpot">
    <h3>ROUND {currentRound} PRESALE</h3>
    <h5>The Pot has raised...</h5>
    <h4>{raisedMice} {raisedMice !== 1 ? `MICE` : "MOUSE"}</h4>
    <h5>with {SECONDS_LEFT_FOR_PRESALE > 3600 ? (SECONDS_LEFT_FOR_PRESALE / 3600).toFixed(2) : SECONDS_LEFT_FOR_PRESALE > 60 ? (SECONDS_LEFT_FOR_PRESALE / 60).toFixed(2) : SECONDS_LEFT_FOR_PRESALE} {SECONDS_LEFT_FOR_PRESALE > 3600 ? "hours left" : SECONDS_LEFT_FOR_PRESALE > 60 ? "minutes left" : "seconds left"}  </h5>
  </PixelBox>;
}
function GameFinished(currentRound: number, IS_FIRST_PLACE: boolean, places: string[], raisedMice: number) {
  return <PixelBox className="App-dashboard-jackpot">
    <h3>ROUND {currentRound} OVER!</h3>
    {IS_FIRST_PLACE ? <h5><span>YOU</span> have won</h5> : <h5><span>{places[0].slice(0, 5)}<span>...</span>{places[0].slice(-3)}</span> has taken</h5>}
    <h4>{raisedMice} MICE!</h4>
    {IS_FIRST_PLACE ? <><h5>CONGRATULATIONS!<br /><br /><i style={{ position: 'relative', zIndex: 2, color: "inherit", fontSize: "small" }}>your rewards will be sent as soon as the next fundraising round starts</i></h5></>
      : <h5>better luck next time!</h5>}
  </PixelBox>;
}
function StartingSoon(currentRound: number) {
  return <PixelBox className="App-dashboard-jackpot">
    <h3>ROUND {currentRound}</h3>
    <h5>will be starting soon, hang tight!</h5>
  </PixelBox>;
}
