import { ethers } from 'ethers';
import React, { useState } from 'react';
import { AppHeaderBar } from '../../AppHeaderBar';
import { useFondueTicketsV2 } from '../../helpers/useFondueTicketsV2';
import { PixelBox } from '../../PixelBox/PixelBox';
import { PixelButton } from '../../PixelButton/PixelButton';
import "./Migration.css";

export default function Migration({ accounts, signerOrProvider }: {
    accounts: string[];
    signerOrProvider: ethers.Signer | ethers.providers.JsonRpcProvider;
}) {
    
    const MIGRATION_CONTRACT = "0xb91517Af14b678BAe633A16864C651669542771E";
    const { fondueContract, contract: ticketContract, fondueBalance, keyBalance: KEYS, UpdateAll: Update, keyMigrationApproval, fondueMigrationApproval, migrationContract } = useFondueTicketsV2(signerOrProvider as ethers.Signer, MIGRATION_CONTRACT);
    const FONDUE = Number(fondueBalance.toString()) / 10 ** 9;

    const [migrated, setMigrated] = useState(false);

    return (
        <header className="App-header">
            <AppHeaderBar
                signer={signerOrProvider as ethers.Signer}
                accounts={accounts}
                title="FONDUE.LAND"
                setShouldLogin={() => { }}
            />
            <div className="App-header-bar-account">
                {migrated ? <PixelBox className='App-dashboard-migration App-dashboard-migration-success'>
                     <h3>
                        MIGRATION SUCCESS!
                    </h3>
                    <p>
                        You have successfully migrated your FONDUE.LAND keys and fondue to the new contract!
                    </p>
                </PixelBox> : <PixelBox className='App-dashboard-migration'>
                    <h3>
                        MIGRATION
                    </h3>
                    {
                        KEYS > 0 || Number(fondueBalance.toString()) > 0 ? <>
                            <p>
                                In order to participate in further rounds of fondue.land you'll need to convert your
                                {" "}<span>{KEYS.toFixed(0)} keys</span> and <span>{FONDUE.toFixed(3)} fondue</span> into their new versions.
                                <br />
                                <br />
                                In total, this will take <span>3 transactions</span>, two approvals and the migration.
                            </p>
                            <div className="App-dashboard-migration-buttons">
                                {!keyMigrationApproval ? 
                                <PixelButton onClick={() => ticketContract?.setApprovalForAll(MIGRATION_CONTRACT, true).then(Update())} className="short-and-long">
                                    APPROVE KEYS
                                </PixelButton> :
                                !fondueMigrationApproval ?
                                <PixelButton onClick={() => fondueContract?.approve(MIGRATION_CONTRACT, ethers.constants.MaxUint256).then(Update())} className="short-and-long">
                                    APPROVE FONDUE
                                </PixelButton> :
                                <PixelButton onClick={() => migrationContract?.ConvertOldKeysAndFondue().then(Update()).then(() => setMigrated(true))} className="short-and-long">
                                    MIGRATE
                                </PixelButton>
                                }
                            </div>
                        </> :
                        <p>
                            You have no old keys or fondue to migrate. Did you already migrate?
                        </p>
                    }
                    
                </PixelBox>}
            </div>
        </header>
    )
}