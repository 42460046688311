import { BigNumber, Contract, ethers, Signer } from "ethers";
import { useEffect, useMemo, useRef, useState } from "react";
import { createNoSubstitutionTemplateLiteral } from "typescript";
import { useContract } from "./useContract";
// import ABI1155 from "../contracts/Test1155.abi"

const DAI_ADDRESS = "0xef977d2f931c1978db5f6747666fa1eacb0d0339";
const ERC20ABI = [
  "function approve(address _operator, uint amount) external returns (bool success)",
  "function allowance(address _owner, address _spender) view returns (uint256 remaining)",
  "function balanceOf(address _owner) view returns (uint256)",
];

export function useFondueTicketsV3(signer: Signer) {
  const jackpotContract = useContract('Jackpot', signer);
  const fondueContract = useContract('Fondue', signer)
  const [currentPrice, setCurrentPrice] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [fondueTotalSupply, setFondueTotalSupply] = useState(0);
  const [keyBalance, setKeyBalance] = useState(0);
  const [daiBalance, setDaiBalance] = useState(0);
  const [earnedDai, setEarnedDai] = useState(0);
  const [lastClaimed, setLastClaimed] = useState(0);
  const [fondueBalance, setFondueBalance] = useState(ethers.BigNumber.from(0));
  const [approvedFor, setApprovedFor] = useState(0);
  const [maxDepositable, setMaxDepositable] = useState(0);
  const [blocks, setBlocks] = useState(0);
  const [raisedMice, setRaisedMice] = useState(0);
  const [maxMiceGoal, setMaxMiceGoal] = useState(100);
  const [miceGoal, setMiceGoal] = useState(0);
  const [miceBalance, setMiceBalance] = useState(0);
  const [presaleEnddate, setPresaleEnddate] = useState(0);
  const [isPresale, setIsPresale] = useState(false);
  const [miceApproved, setMiceApproved] = useState(false);
  const [keysApprovedForFondue, setKeysApprovedForFondue] = useState(false);
  const [beforeRound, setBeforeRound] = useState(false);
  const [places, setPlaces] = useState([ethers.constants.AddressZero,ethers.constants.AddressZero]);
  const [endDate, setEndDate] = useState(0);
  const daiContract = useMemo(() => new Contract(DAI_ADDRESS, ERC20ABI, signer), [signer]);
  const miceContract = useMemo(() => new Contract("0x4e9c30CbD786549878049f808fb359741BF721ea", ["function balanceOf(address user, uint256 _id) external view returns(uint256)", "function isApprovedForAll(address,address) external view returns(bool)", "function setApprovalForAll(address from, bool _allowed) external"], signer), [signer]);
  const [events, setEvents] = useState([]);
  const [currentRound, setCurrentRound] = useState(0); 

  useEffect(() => {
    GetActivityData().then(({block, events}) => {
      setEvents((events.slice(0, 15) as []).map((i: any) => {
        const obj = {
          ...i,
          amount: (i.purchaser || i.depositer) ? Math.floor(i.amount) : Math.floor(i.amount/1e9),
          createdAt: new Date(i.createdAt * 1000),
          type: i.purchaser ? "purchase" : i.depositer ? "takeThePot" : "migration",
          address: i.purchaser ? i.purchaser : i.amountOfFondue ? i.address : i.depositer,
          presale: !!i.isPresale
        };

        return obj;
      }) as []);
    });
  }, [places, endDate, totalSupply])

  useEffect(() => {},)


  useEffect(() => {
    const listener: ethers.providers.Listener = (block) => {
      setBlocks(block);
    };
    // listen for current block and call setBlock
    if (signer?.provider){
      signer.provider?.on('block', listener);
      return () => {signer.provider?.removeListener('block', listener)}
    };
  }, [signer])


  useEffect(() => {
    (UpdateContractInfo())();
  }, [jackpotContract]);

  useEffect(() => {
    (UpdateDaiInfo())();
  }, [daiContract]);
  
  useEffect(() => {
    (UpdateFondueInfo())();
  }, [fondueContract]);
  useEffect(() => {
    (UpdateMouseInfo())();
  }, [miceContract]);

  useEffect(() => {
    setTimeout(() => {
      UpdateAll()();
      }, 1000 * 12)}, []
    );  
    
  return {
    approvedFor,
    beforeRound,
    blocks,
    contract: jackpotContract, 
    currentRound,
    daiBalance,
    daiContract,
    earnedDai,
    endDate,
    endDateFormated: BlocksToDate(endDate - blocks),
    events,
    fondueBalance,
    fondueContract,
    fondueTotalSupply,
    isPresale,
    jackpotContract,
    raisedMice,
    miceApproved,
    miceContract,
    maxMiceGoal,
    miceGoal,
    miceBalance,
    presaleEnddate,
    keyBalance,
    keysApprovedForFondue,
    lastClaimed,
    maxDepositable,
    places, 
    price: currentPrice, 
    totalSupply,
    UpdateAll,
    UpdateContractInfo,
    UpdateDaiInfo,
    UpdateFondueInfo
  } 

  // waits for tx if passed in
  function UpdateAll() {
    return (tx?: ethers.providers.TransactionResponse) => {
      return (tx ? tx?.wait(1): Promise.resolve()).then(() => Promise.all([
        UpdateContractInfo()(),
        UpdateDaiInfo()(),
        UpdateFondueInfo()(),
        UpdateMouseInfo()()
      ]))
    }
  }

  function UpdateMouseInfo() {
    return async () => {
      if(!signer) return 0;
      return signer?.getAddress()?.then(address => Promise.all([
        miceContract.balanceOf(address, 0),
        miceContract.isApprovedForAll(address, jackpotContract?.address)
      ])).then(([miceBalance, miceApproved]) => {
        setMiceBalance(miceBalance.toNumber());
        setMiceApproved(miceApproved);
      });
    }
  }

  function UpdateFondueInfo() {
    return async () => {
      return signer?.getAddress()?.then(address => Promise.all([
        jackpotContract?.maxDepositable().then((num: ethers.BigNumber) => num.toNumber()).catch(() => 0),
        Promise.all([
          jackpotContract?.first_place(),
        ]),
        jackpotContract?.timer_end_date().then((num: ethers.BigNumber) => num.toNumber()).catch(() => 0),
        fondueContract?.balanceOf(address),
        fondueContract?.totalSupply().then((num: ethers.BigNumber) => num.toNumber() / 10 ** 9),
        fondueContract?.rewards(address),
        fondueContract?.rewardsPerToken(),
      ])).then(([d, [first], endDate, fondueBal, fondueSupply, {
        accumulated,
        checkpoint
      }, {accumulated: total_accumulated, lastUpdated, rate}]) => {
        setMaxDepositable(d);
        setPlaces([first]);
        setEndDate(endDate);
        setFondueBalance(fondueBal);
        const ONE_ETHER = ethers.constants.WeiPerEther
        const additional = accumulated.add(fondueBal.mul(total_accumulated.sub(checkpoint))).div(ONE_ETHER)
        setFondueTotalSupply(fondueSupply);
        setEarnedDai(accumulated.add(additional).div(10 ** 9).toNumber() / 10 ** 9);
      })
    }
  };

  function UpdateDaiInfo() {
    return async () => {
      signer?.getAddress().then(address => Promise.all([
        daiContract?.allowance(address, jackpotContract?.address),
        daiContract?.balanceOf(address),
      ]).then(([a, b]) => {
        setApprovedFor(Number((Number(a.toString()) / 10 ** 18).toFixed(2)));
        setDaiBalance(Number((Number(b.toString()) / 10 ** 18).toFixed(2)));
      }));
    };
  }


  function UpdateContractInfo() {
    return async () => {
      signer?.getAddress()?.then(address => Promise.all([
        jackpotContract?.totalSupply(0),
        jackpotContract?.balanceOf(address, 0).then((num: ethers.BigNumber) => num.toNumber()).catch(() => 0),
        jackpotContract?.isApprovedForAll(address, jackpotContract?.address),
        jackpotContract?.GetCost(1),
        jackpotContract?.before_round(),
        jackpotContract?.PresaleIsDone(),
        jackpotContract?.GetMice().then((x: ethers.BigNumber) => x.toNumber()),
        jackpotContract?.CurrentRound().then((x: ethers.BigNumber) => x.toNumber()),
        jackpotContract?.GetGoal(),
        jackpotContract?.presale_end_date().then((num: ethers.BigNumber) => num.toNumber()).catch(() => 0),
      ])).then(([a,b,c,d,e,f,h,i,j,k]) => {
          setTotalSupply(a.toNumber());
          setKeyBalance(b);
          setKeysApprovedForFondue(c);
          setCurrentPrice(Number((Number(d.toString()) / 10 ** 18).toFixed(4)));
          setBeforeRound(e);
          setIsPresale(!f);
          setRaisedMice(h);
          setCurrentRound(i);
          setMiceGoal(j.toNumber());
          setPresaleEnddate(k);
      });
    };
  }

  function BlocksToDate(blocks: number, secondsPerBlock = 2.2) {
    const seconds = blocks * secondsPerBlock;
    
    // format as "x hours, y minutes, z seconds"
    const parts = [
      Math.floor(seconds / (60 * 60)),
      Math.floor(Math.floor((seconds % (60 * 60))) / 60),
      Math.floor((seconds % (60 * 60))) % 60,
    ];
    const labels = ['hour', 'minute', 'second'];
    const formatted = parts.map((part, index) => {
      return part ? `${part} ${labels[index]}${part > 1 ? 's' : ''}` : '';
    }).filter(Boolean).join(', ');
    return formatted;
}
  
}

async function GetActivityData() {
  const data = await fetch("https://graph.t.hmny.io/subgraphs/name/fondue", {
    "headers": {
      "accept": "application/json",
      "accept-language": "en-US,en;q=0.9",
      "content-type": "application/json",
    },
    "body": "{\"query\":\"{\\n  _meta {\\n    block { number }\\n  }\\n  \\n  migrations(first: 10, subgraphError: allow) {\\n    id,\\n    createdAt,\\n    transaction,\\n    amountOfKeys,\\n    amountOfFondue\\n    address\\n  }\\n  \\n \\n  \\n  purchases (first: 20, orderBy: createdAt, orderDirection: desc) {\\n    createdAt\\n    amount\\n  id\\n    purchaser\\n   isPresale\\n  }\\n  \\n  takeThePots (first: 20, orderBy: createdAt, orderDirection: desc) {\\n    createdAt\\n    amount\\n    id\\n    depositer\\n  }\\n}\",\"variables\":null,\"operationName\":null}",
    "method": "POST",
    "mode": "cors",
    "credentials": "omit"
  }).then(b => b.json()).then(d => d.data);
  const block = data._meta.block.number;
  const purchases = data.purchases;
  const takeThePots = data.takeThePots;
  const migrations = data.migrations;
  // merge purchase events and takeThePot events into one array and order by createdAt
  const events = [...purchases, ...takeThePots, ...migrations].sort((b,a) => {
    return a.createdAt - b.createdAt;
  }
  );

  return {events, block};
}